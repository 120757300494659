import { useStaticQuery, graphql } from 'gatsby';
import _ from 'lodash';

const isValidPath = val => {
  if(_.some(_.split(val, '.'), test => test === '')) {
    return false;
  }
  return true;
}

const dataTypes = [
  'Value_Text',
  'Value_Boolean',
  'Value_Int',
  'Value_Date',
  'Value_List',
  'Value_Attachment',
  'Value_LText',
]
const useMeta = () => {
  const metaData = useStaticQuery(graphql`
    query MetaQuery {
      data: allAirtable(filter: {table: {eq: "Meta"}}) {
        items: nodes {
          id
          data {
            Name
            Key
            Value_Text
            Value_Boolean
            Value_Int
            Value_Date
            Value_List
            Value_Attachment {
              id
              size
              type 
              publicURL: url
              filename
            }
            Value_LText
          }
        }
      }
    }
  `);
  const rtn = {};
  _.map(metaData.data.items, ({ data }) => {
    const { Name, Key } = data;
    const values = _.values(_.pick(data, dataTypes));
    if(!_.isNull(Name) && !_.isNull(Key)) {
      const dataPath = `${Name}.${Key}`;
      if(dataPath && isValidPath(dataPath)) {
        const value = _.find(values, test => !_.isNull(test));
        _.set(rtn, `${Name}.${Key}`, value);  
      }
    }
  });
  return rtn;
};

export const useMetaOverride = (name = 'meta', item = {}) => {
  const metaData = useMeta();
  return _.defaultsDeep({ frontmatter: _.get(metaData, name, {}) }, item);
};

export default useMeta;

